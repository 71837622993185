import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Router from 'next/router';
import { withSubscription } from '../user';
import ReleasePage from './components/ReleasePage';
import { selectCurrentRelease, selectCurrentReleaseChannel } from '../channel';
import { selectCurrentlyPlaying, withPlayer } from '../player';
import { Release } from '../common/types/Release';
import { Track } from '../common/types/Track';
import { Channel } from '../common/types/Channel';
import { AppState } from '../common/types/AppState';

type Props = {
  dispatch: Dispatch;
  release: Release;
  releaseChannel: string;
  currentlyPlaying: Track;
  isSubscribed: boolean;
  channel: Channel;
};

class ReleasePageContainer extends React.Component<Props> {
  //@ts-ignore
  handlePlay = this.handlePlay.bind(this);
  //@ts-ignore
  handlePlay(track: Track) {
    //@ts-ignore
    const { release, playTrack, canAccess, getRedirectRoute } = this.props;

    if (canAccess(track)) {
      playTrack(track, release.tracks);
    } else {
      console.log('track cannot play');
      Router.push(getRedirectRoute(track));
    }
  }

  render() {
    //@ts-ignore
    return (
      //@ts-ignore
      <ReleasePage
        handlePlay={this.handlePlay}
        release={this.props.release}
        releaseChannel={this.props.releaseChannel}
        currentlyPlaying={this.props.currentlyPlaying}
        isSubscribed={this.props.isSubscribed}
        channel={this.props.channel}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    release: selectCurrentRelease(state),
    releaseChannel: selectCurrentReleaseChannel(state),
    currentlyPlaying: selectCurrentlyPlaying(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return { dispatch };
};

export default compose(
  withSubscription,
  withPlayer,
  connect(mapStateToProps, mapDispatchToProps)
)(ReleasePageContainer);
