import * as React from 'react';
import TrackItem from './TrackItem';
import styled from 'styled-components';
import { withSubscription } from '../../withSubscription';

import { Track } from '../../common/types/Track';

type Props = {
  tracks: Array<Track>;
  handlePlay?: Function;
  currentlyPlaying?: Track;
};

type InjectedProps = {
  canAccess: (track: Track) => {};
};

class ReleaseTrackList extends React.Component<Props & InjectedProps, any> {
  static defaultProps = {
    tracks: [],
  };

  render() {
    const { tracks, handlePlay, currentlyPlaying, canAccess } = this.props;
    return (
      <StyledOrderedList data-test="content-list">
        {tracks.map((track) => (
          <TrackItem
            key={track.trackId}
            track={track}
            handlePlay={handlePlay}
            currentlyPlaying={currentlyPlaying}
            isLocked={!canAccess(track)}
          />
        ))}
      </StyledOrderedList>
    );
  }
}

const StyledOrderedList = styled.ol`
  display: block;
`;

// @ts-ignore
export default withSubscription(ReleaseTrackList);
