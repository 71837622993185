import * as React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Icon } from '../../common';
import { ContentLockLabel } from '../../layout';
import { Track } from '../../common/types/Track';
import { Icons } from '../../common/components/Icon';
type Props = {
  track: Track;
  title: string;
  handlePlay?: Function;
  currentlyPlaying?: Track;
  isLocked: boolean;
};

class TrackItem extends React.Component<Props, any> {
  static defaultProps: any;

  handlePlay = () => {
    const { handlePlay, track } = this.props;
    if (handlePlay) {
      handlePlay(track);
    }
  };

  isCurrentTrack() {
    const { track, currentlyPlaying } = this.props;
    if (currentlyPlaying) {
      if (track.trackId === currentlyPlaying?.trackId) {
        return true;
      }
    }
    return false;
  }

  render() {
    const { track, isLocked } = this.props;
    return (
      <TrackItemStyled data-test="content-list-item">
        <TrackNumber>
          {this.isCurrentTrack() ? (
            <Icon icon={Icons.audio} size={16} />
          ) : (
            track.trackNumber
          )}
        </TrackNumber>
        <TrackInfo>
          <TrackTitle onClick={this.handlePlay} current={this.isCurrentTrack()}>
            {track.name}
          </TrackTitle>

          <TrackTools className="absolute right-0">
            {track.story && (
              <Link
                as={`/audio/${track.albumId}/${track.trackId}`}
                href={`/audio/[id]/[trackId]`}>
                <a>
                  <FiMoreHorizontal
                    size={48}
                    className="text-accent-primary hover:text-foreground-primary"
                  />
                </a>
              </Link>
            )}
          </TrackTools>
          <TrackDuration />
          {isLocked && (
            <ContentLockLabel small data-test="content-lock">
              <Icon icon={Icons.lock} color="#ffffff" size={16} />
            </ContentLockLabel>
          )}
        </TrackInfo>
      </TrackItemStyled>
    );
  }
}

const TrackItemStyled = styled.li`
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: stretch;
  line-height: 48px;
  font-size: 14px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.hairline};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.hover};
  }
`;

const TrackNumber = styled.div`
  width: 48px;
  flex: 0 0 48px;
  text-align: center;
`;

const TrackInfo = styled.div`
  display: flex;
  box-sizing: border-box;
  x-justify-content: space-around;
  x-align-items: stretch;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

const TrackTitle = styled.span`
  width: 100%;
  font-weight: ${(props) => (props.current ? 'bold' : 'normal')};
  overflow: hidden;
  position: relative;
  display: block;
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
`;

const TrackTools = styled.div``;

const TrackDuration = styled.span`
  flex: 0 0 48px;
`;

export default TrackItem;
