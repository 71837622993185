import * as React from 'react';
import styled from 'styled-components';
import { FadeIn } from '../../common';

import {
  LayoutContainer,
  PageBody,
  media,
  FullscreenBackground,
  BackgroundFade,
} from '../../layout';
import ReleaseTrackList from './ReleaseTrackList';
import { sizeCloudinaryImage, HelmetIntl, Loading } from '../../common';
import { Release } from '../../common/types/Release';
import { Track } from '../../common/types/Track';
import { Channel } from '../../common/types/Channel';
import { User } from '../../common/types/User';

type Props = {
  release: Release;
  releaseChannel: string;
  handlePlay?: Function;
  currentlyPlaying?: Track;
  match: Object; // TODO make a match object type
  channel: Channel;
  isSubscribed: boolean;
  user: User;
};

class ReleasePage extends React.Component<Props> {
  render() {
    const release = this.props.release;
    if (!release) {
      return <Loading>Loading release...</Loading>;
    }
    const { name, year, tracks, imageUrl } = this.props.release;
    const { handlePlay, channel, isSubscribed } = this.props;

    if (!channel) {
      return <Loading>Loading...</Loading>;
    }

    const releaseChannel = this.props.releaseChannel;
    // TODO This is not international, noob!
    const plural = tracks.length === 0 || tracks.length > 1 ? true : false;
    return (
      <LayoutContainer>
        <HelmetIntl
          titleId="app.meta.audio.release.title"
          descriptionId="app.meta.audio.release.description"
          values={{
            channel: releaseChannel,
            release: name,
            image: imageUrl ? imageUrl : null,
          }}
        />
        <FullscreenBackground image={sizeCloudinaryImage(imageUrl, 800)}>
          <BackgroundFade />
        </FullscreenBackground>

        <PageBody isContainer>
          <ReleaseImage>
            <FadeIn>
              {(onLoad) => (
                <img
                  src={sizeCloudinaryImage(imageUrl, 300)}
                  alt={`${releaseChannel} ${name}`}
                  onLoad={onLoad}
                />
              )}
            </FadeIn>
          </ReleaseImage>
          <ReleaseContent>
            <ReleaseMeta>
              <ReleaseChannel>{releaseChannel}</ReleaseChannel>
              <ReleaseTitle>{name}</ReleaseTitle>
              <ReleaseDetails>
                {year} &bull; {tracks.length} track
                {plural ? 's' : ''}
              </ReleaseDetails>
            </ReleaseMeta>
            <ReleaseTrackList
              // @ts-ignore
              handlePlay={handlePlay}
              tracks={tracks}
              currentlyPlaying={this.props.currentlyPlaying}
              isSubscribed={isSubscribed}
            />
          </ReleaseContent>
        </PageBody>
      </LayoutContainer>
    );
  }
}

const ReleaseImage = styled.div`
  padding: 0px;
  box-sizing: border-box;
  max-width: 128px;
  width: 100%;
  overflow: hidden;
  margin-left: ${(p) => p.theme.padding * 2}px;

  img {
    border-radius: 3px;
  }

  ${media.medium`
    float: left;
    max-width: 25%;
  `};

  ${media.large`
    margin-left: 0px;
  `};
`;

const ReleaseChannel = styled.div`
  color: ${(props) => props.theme.foreground2};
  padding-bottom: 6px;
  font-size: 16px;
`;

const ReleaseContent = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  padding: 12px 0;
  ${media.medium`
    padding-left: 24px;
    float: left;
    width: 70%;
  `};
`;

const ReleaseMeta = styled.div`
  padding: ${(p) => p.theme.padding}px ${(p) => p.theme.padding * 2}px;
`;

const ReleaseTitle = styled.h1`
  font-size: 32px;
  font-weight: 300;
  line-height: 42px;
  padding-bottom: 10px;
`;

const ReleaseDetails = styled.span`
  color: ${(props) => props.theme.foreground2};
  display: inline-block;
  margin-bottom: 24px;
`;

export default ReleasePage;
